@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=block");

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
html {
  max-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #000000;
}
.App {
  text-align: center;
}


body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    background-color: var(--bs-body-bg);
    color: #212529;
    color: var(--bs-body-color);
    font-family: system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-family: var(--bs-body-font-family);
    font-size: 1rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    margin: 0;
    text-align: var(--bs-body-text-align);
}

.Mobile-App {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  animation: fadeIn 2s;
  height: max(13vw, 100px);
  border-radius: 20px;
  margin-top: 10px;
}

.logo-circle {
  height: 6vmin;
  pointer-events: none;
  margin-top: 5%;
  max-width: 5vh;
  max-height: auto;
}

.App-logo-mobile {
  animation: fadeIn 2s;
  max-width: 15vh;
  max-height: auto;
  border-radius: 10px;
  margin-top: 20%;

}

.App-header {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 10vw;
  font-weight: 900;
  margin-top: 50%;
  margin-bottom: 5%;
}

.App-header-web {
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: max(9vw, 30px);
  font-weight: 900;
  margin-top: 5%;
}
content {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  font-weight: 900;
  width: 900px;
}
p {
  margin-left: 1rem;
  margin-right: 5vw;
  margin-top: 0%;
  margin-bottom: 30px;
}
s {
  font-size: 20px;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 10vw;
  color: white;
  font-size: 14px;
}

.web-footer {
  color: white;
  font-size: max(1vw, 8px);
  bottom: 2vw;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.link {
  text-decoration: none;
  color: white;
}

.App-content {
  text-align: left;
  display: inline-block;
  max-width: 1000px;
  color: white;
}

.App-body-web {
  color: white;
  font-size: 4vh;
  margin-bottom: 5%;
}

.App-body-mobile {
  color: white;
  font-size: 3vh;
  margin-bottom: 5%;
}

.app-store-logo {
  width: auto;
  height: 100px;
  max-height: 10vh;
  margin-top: 2%;
}

.app-store-logo-mobile {
  width: auto;
  height: 100px;
  max-height: 8vh;
  margin-top: 10%;
}
